import React, { ChangeEvent } from "react";
import { capitalize } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import { Container, Input } from "./__styles__/Switch";
import Wrapper from "./Wrapper";

export type LabelProps = "yes/no" | "true/false";

type SwitchProps = {
  value: boolean;
  name: string;
  label?: string;
  onChange: (value: boolean) => void;
  size?: "tiny" | "small" | "medium";
  disabled?: boolean;
  labels?: LabelProps;
  testId?: string;
  required?: boolean;
  description?: Maybe<string>;
  tabIndex?: number;
};

export default ({
  value,
  name,
  label,
  onChange,
  size,
  disabled = false,
  labels = "yes/no",
  testId,
  required,
  description,
  tabIndex = 0,
}: SwitchProps) => {
  const checkboxValueChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.checked);
  };

  const LABELS = {
    "yes/no": {
      true: "yes",
      false: "no",
    },
    "true/false": {
      true: "true",
      false: "false",
    },
  };

  const hotkeyRef = useHotkeys<HTMLDivElement>(
    ["space", "return"],
    () => {
      onChange(!value);
    },
    { preventDefault: true }
  );

  return (
    <Wrapper
      label={label}
      name={`${name}-switch`}
      required={required}
      description={description}
      disabled={disabled}
      printValue={value ? LABELS[labels].true : LABELS[labels].false}
    >
      <Container
        size={size}
        trueFalse={labels === "true/false"}
        role={"switch"}
        aria-label={capitalize(name.replace(/-/gi, " "))}
        aria-checked={!!value}
        tabIndex={tabIndex}
        ref={hotkeyRef}
        disabled={disabled}
      >
        <Input
          type="checkbox"
          checked={!!value}
          onChange={checkboxValueChanged}
          disabled={disabled}
          id={`${name}-switch`}
          data-testid={testId}
        />
        <label htmlFor={`${name}-switch`}>
          <span aria-hidden={true}>{LABELS[labels].true}</span>
          <span aria-hidden={true}>{LABELS[labels].false}</span>
        </label>
      </Container>
    </Wrapper>
  );
};
