import React, { ChangeEvent } from "react";
import { Control, Controller, UseControllerProps } from "react-hook-form";
import { FlexColumn } from "../Common/__styles__/Layout";
import { PrintOnly } from "../Common/__styles__/PrintOnly";
import { ScreenOnly } from "../Common/__styles__/ScreenOnly";
import { Icon } from "../Common/Icons/LucideIcons";
import { Body } from "../Common/Typography";
import {
  CheckboxWrapper,
  CustomCheckbox,
  HiddenInput,
  LabelRow,
  Wrapper,
} from "./__styles__/Checkbox";

interface GenericProps {
  name?: string;
  disabled?: boolean;
  readonly?: boolean;
  label?: string;
  description?: string;
  error?: string;
  required?: boolean;
  additionalOnChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
}

type CheckboxProps = GenericProps & {
  value?: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox = ({
  value = false,
  name,
  onChange,
  additionalOnChange,
  disabled = false,
  readonly = false,
  label,
  description,
  error,
  required,
}: CheckboxProps) => {
  const _handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    onChange(evt.target.checked);
    if (additionalOnChange) {
      additionalOnChange(evt);
    }
  };

  return (
    <>
      <ScreenOnly>
        <Wrapper
          data-testid="checkbox"
          disabled={disabled}
          readonly={readonly}
          checked={value}
        >
          <HiddenInput
            type="checkbox"
            checked={value}
            onChange={_handleChange}
            disabled={disabled || readonly}
            id={name}
          />
          <CheckboxWrapper>
            <CustomCheckbox checked={value} error={!!error}>
              {value && (
                <Icon iconName="check" color="contentPrimaryDark" size={12} />
              )}
            </CustomCheckbox>
          </CheckboxWrapper>

          {label && (
            <FlexColumn>
              <LabelRow>
                <Body type="regular" size="default" as="div">
                  {label}
                </Body>
                {required && (
                  <Body
                    type="regular"
                    size="default"
                    color="contentCritical"
                    as="div"
                  >
                    *
                  </Body>
                )}
              </LabelRow>
              {description && (
                <Body
                  type="regular"
                  size="default"
                  color="contentSecondary"
                  as="div"
                >
                  {description}
                </Body>
              )}
            </FlexColumn>
          )}
        </Wrapper>
      </ScreenOnly>
      <PrintOnly flexRow data-testid={`print-only-${name}`}>
        <Body type="regular" size="default" as="div">
          {value ? "Yes" : "No"}
        </Body>
      </PrintOnly>
    </>
  );
};

type ReactHookFormCheckboxProps = GenericProps &
  Omit<UseControllerProps, "control"> & { control: Control<any> };

export const ReactHookFormCheckbox = (props: ReactHookFormCheckboxProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        required: props.required,
        ...props.rules,
      }}
      defaultValue={false}
      render={({ field, fieldState: { error } }) => {
        return (
          <Checkbox
            {...props}
            value={field.value ?? false}
            onChange={value => field.onChange(value)}
            error={error?.message}
          />
        );
      }}
    />
  );
};

export default Checkbox;
