import React from "react";
import {
  FieldPath,
  FieldPathByValue,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import {
  InputLabel,
  MarkdownGrid,
  MarkdownTextarea,
  PreviewLabel,
  StyledPreviewContainer,
} from "./__styles__/Markdown";

type MarkdownProps<
  TFieldValues extends FieldValues,
  Path extends FieldPath<TFieldValues>
> = Omit<UseControllerProps<TFieldValues, Path>, "control"> & {
  inputLabel?: string;
  previewLabel?: string;
  // we specifically require a `control` prop to allow
  // TS to infer the possible types of `name`
  control: UseControllerProps<TFieldValues, Path>["control"];
  previewContent?: string;
  placeholderContent?: string;
};

export const Markdown = <
  Form extends FieldValues,
  Path extends FieldPathByValue<Form, string>
>({
  name: fieldName,
  inputLabel = "Update",
  previewLabel = "Preview",
  previewContent = undefined,
  placeholderContent,
}: MarkdownProps<Form, Path>) => {
  const { register, watch } = useFormContext();

  const onlyPreview = !!previewContent;

  let previewMarkdown = "";

  //if there is no content in the form input and placeholder content is present, use placeholder content
  if (!watch(fieldName) && placeholderContent) {
    previewMarkdown = placeholderContent;
  }
  //if there is previewContent, use that
  else if (previewContent) {
    previewMarkdown = previewContent;
  }
  //in all other cases watch the form input
  else {
    previewMarkdown = watch(fieldName);
  }

  return (
    <MarkdownGrid onlyPreview={onlyPreview}>
      {!onlyPreview && <InputLabel>{inputLabel}</InputLabel>}
      <PreviewLabel onlyPreview={onlyPreview}>{previewLabel}</PreviewLabel>
      {!onlyPreview && (
        <MarkdownTextarea
          {...register(fieldName)}
          placeholder={placeholderContent}
          data-testid={`${fieldName}-textarea`}
        />
      )}
      <StyledPreviewContainer
        onlyPreview={onlyPreview}
        markdown={previewMarkdown}
        data-testid={`${fieldName}-preview`}
      />
    </MarkdownGrid>
  );
};
