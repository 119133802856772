import React, { useState } from "react";
import { Bubble, List } from "./__styles__/MultiselectBubble";
import Wrapper from "./Wrapper";

const ItemBubble = ({
  item,
  selectedItems,
  onChange,
  disabled,
  readonly,
}: {
  item: string;
  selectedItems: Array<string>;
  onChange: any;
  disabled: boolean;
  readonly: boolean;
}) => {
  const [selected, setSelected] = useState(selectedItems.includes(item));

  const handleOnChange = () => {
    if (disabled || readonly) return;

    setSelected(!selected);
    const index = selectedItems.indexOf(item);
    if (index === -1) {
      selectedItems.push(item);
    } else {
      selectedItems.splice(index, 1);
    }
    onChange(selectedItems);
  };

  return (
    <Bubble
      onClick={handleOnChange}
      onKeyDown={e => {
        if (e.key === "Enter") handleOnChange();
      }}
      tabIndex={0}
      selected={selected}
      disabled={disabled || readonly}
      readonly={readonly}
    >
      {item}
    </Bubble>
  );
};

export interface MultiselectBubbleProps {
  items: Array<string>;
  selectedItems: Array<string>;
  onChange: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  label?: Maybe<string>;
  error?: string;
  description?: Maybe<string>;
  tooltip?: string;
}

export const MultiselectBubble = ({
  items,
  selectedItems = [],
  onChange,
  name,
  required,
  disabled = false,
  readonly = false,
  label,
  error,
  description,
  tooltip,
}: MultiselectBubbleProps) => (
  <Wrapper
    name={name}
    required={required}
    label={label}
    error={error}
    description={description}
    tooltip={tooltip}
    printValue={selectedItems.join(", ")}
  >
    <List hasTooltip={!!tooltip}>
      {items.map((item, index) => (
        <ItemBubble
          item={item}
          selectedItems={selectedItems}
          onChange={onChange}
          disabled={disabled}
          readonly={readonly}
          key={index}
        />
      ))}
    </List>
  </Wrapper>
);
