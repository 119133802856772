import "./__styles__/Datepicker.scss";
import React, { ReactNode } from "react";
import moment from "moment";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from "react-hook-form";
import { calendarStyles, DatepickerInput } from "./__styles__/Datepicker";
import Wrapper from "./Wrapper";

const parseValue = (value: Maybe<string | Date>) => {
  if (!value) return null;

  if (value instanceof Date) return value;

  return moment(moment.utc(value).format("YYYY-MM-DD")).toDate();
};

function toString(val: Maybe<Date>) {
  if (!val) return "";
  const date = moment.utc(val);
  return date.format("YYYY-MM-DD");
}

type DatepickerProps = {
  label?: string;
  compactLabel?: boolean;
  name: string;
  value: Maybe<Date | string>;
  helperText?: Maybe<string>;
  error?: string;
  onChange: (value: string) => void;
  size?: "smaller" | "small" | "medium" | "large";
  children?: ReactNode;
  placeholderText?: string;
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  description?: Maybe<string>;
  minDate?: Maybe<Date>;
  maxDate?: Maybe<Date>;
  tooltip?: string;
} & Omit<ReactDatePickerProps, "onChange" | "value">;

const Datepicker = ({
  label,
  compactLabel = false,
  name,
  value,
  helperText,
  error,
  onChange,
  size = "medium",
  children,
  required,
  description,
  minDate,
  maxDate,
  tooltip,
  disabled,
  readonly,
  ...props
}: DatepickerProps) => {
  calendarStyles();
  return (
    <Wrapper
      label={label}
      compactLabel={compactLabel}
      name={name}
      helperText={helperText}
      error={error}
      addon={children}
      required={required}
      description={description}
      labelTabIndex={-1}
      tooltip={tooltip}
      printValue={toString(parseValue(value))}
    >
      <DatePicker
        selected={parseValue(value)}
        onChange={date => onChange(toString(date as Maybe<Date>))}
        customInput={
          <DatepickerInput
            size={size}
            error={!!error}
            disabled={disabled || readonly}
            isReadonly={readonly}
            isDisabled={disabled}
          />
        }
        id={name}
        maxDate={maxDate ?? new Date("12/31/9999")}
        // The default is effectively no minimum; however, the date picker doesn't like it when I set it to the year 0
        minDate={minDate ?? new Date("01/01/1000")}
        disabled={disabled || readonly}
        {...props}
      />
    </Wrapper>
  );
};

export default Datepicker;

type ReactHookFormDatepickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules"> &
  Omit<DatepickerProps, "onChange" | "value"> & {
    onChange?: (value: Maybe<Date>) => void;
  };

export const ReactHookFormDatepicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  required = false,
  onChange,
  size = "medium",
  label,
  compactLabel,
  description,
  children,
  ...props
}: ReactHookFormDatepickerProps<TFieldValues, TName>) => {
  calendarStyles();
  return (
    <Controller<TFieldValues, TName>
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <Wrapper
          label={label}
          compactLabel={compactLabel}
          name={name}
          error={fieldState.error?.message}
          addon={children}
          required={required}
          description={description}
          labelTabIndex={-1}
          printValue={toString(parseValue(field.value))}
        >
          <DatePicker
            customInput={
              <DatepickerInput
                size={size}
                error={!!fieldState.error?.message}
              />
            }
            selected={parseValue(field.value)}
            name={name}
            id={name}
            error={fieldState.error?.message}
            required={required}
            {...props}
            onChange={(value: Date | null) => {
              field.onChange(toString(value as Maybe<Date>));
              if (value) {
                onChange?.(new Date(value));
              }
            }}
          />
        </Wrapper>
      )}
    />
  );
};
