import React, { ComponentProps } from "react";
import { flatMap } from "lodash";
import { useDropzone as useReactDropzone } from "react-dropzone";
import {
  Controller,
  FieldPath,
  FieldPathByValue,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { MIME_TYPE, MIME_TYPES_TO_EXTENSIONS } from "common/constants";
import {
  formatCollection,
  splitFileNameAndExtension,
} from "common/utils/strings";
import { useStatusToasts } from "../../hooks/useStatusToasts";
import {
  DropzoneComponent,
  Filename,
  FileRowComponent,
  FileTable,
  Info,
  Remove,
  SuccessOrErrorIcon,
} from "../FileUploads";
import Label from "./Label";

export type Props<
  TFieldValues extends FieldValues,
  Path extends FieldPath<TFieldValues>
> = Omit<UseControllerProps<TFieldValues, Path>, "control"> & {
  // we specifically require a `control` prop to allow
  // TS to infer the possible types of `name` and `rules`
  control: UseControllerProps<TFieldValues, Path>["control"];
  allowedMimeTypes: Array<MIME_TYPE>;
  fileHeader?: React.ReactNode;
  useDropzone?: (
    args: Pick<NonNullable<Parameters<typeof useReactDropzone>[0]>, "onDrop">
  ) => ReturnType<typeof useReactDropzone>;
  label?: string;
  required?: boolean;
  description?: string;
} & Pick<ComponentProps<typeof DropzoneComponent>, "compact" | "hasImage">;

// This component will only work if the parent form
// is wrapped in a `FormProvider` from `react-hook-form`
export const ReactHookFormSingleFileUpload = <
  Form extends FieldValues,
  Path extends FieldPathByValue<Form, { blob: any }>
>({
  useDropzone = undefined,
  allowedMimeTypes,
  name: fieldName,
  rules,
  fileHeader,
  control,
  compact,
  hasImage,
  label,
  required,
  description,
}: Props<Form, Path>) => {
  const { addErrorToast } = useStatusToasts();
  const { setValue, watch, resetField } = useFormContext<Form>();

  const allowedExtensions = flatMap(
    allowedMimeTypes.map(mimeType => MIME_TYPES_TO_EXTENSIONS[mimeType])
  );

  const currentValue = watch(fieldName);

  const onDrop = (acceptedFiles: Array<File & { path?: string }>) => {
    if (acceptedFiles.length > 1) {
      addErrorToast("You can only upload 1 file");
      return;
    }

    if (acceptedFiles[0]) {
      const upload = acceptedFiles[0];

      const blob = Object.assign(upload, { url: URL.createObjectURL(upload) });

      // doing an extension validation here doesn't work because
      // shoudValidate: true ends up clobbering custom errors and
      // we *have* to re-validate to trigger *other* validations on the form
      setValue(
        fieldName,
        {
          ...currentValue,
          blob,
          // Most browsers provide path, but Electron doesn't, so we can fall back to name
          originalFilename: blob.path || upload.name,
        },
        { shouldValidate: true }
      );
    }
  };

  const DropzoneContent = () => (
    <>
      <h5>Drop your file here</h5>
      <p>
        or <span>browse</span> to choose a file
      </p>
    </>
  );

  const validFileExtension = (file?: {
    originalFilename: string;
    blob: unknown;
  }) => {
    if (file?.blob) {
      const fileExtension = splitFileNameAndExtension(
        file.originalFilename!
      ).fileExt;

      if (!fileExtension) {
        return "File extension is missing";
      }
      if (
        !allowedExtensions.find(extension => extension === `.${fileExtension}`)
      ) {
        return `The file you uploaded is not a ${formatCollection(
          allowedExtensions,
          "or"
        )} file`;
      }
    }

    return true;
  };

  // we need to add a "invalid extension" validation rule,
  // but we don't want to override any other validation rules
  // so we perform this abomination of a ternary
  const validate =
    typeof rules?.validate === "function"
      ? { custom: rules.validate, "file-extension": validFileExtension }
      : {
          ...rules?.validate,
          custom: () => true,
          "file-extension": validFileExtension,
        };

  return (
    <>
      {!!label && (
        <Label text={label!} required={required} description={description} />
      )}
      <Controller
        control={control}
        name={fieldName}
        rules={{ validate, ...rules }}
        render={({ fieldState }) => {
          return !currentValue?.blob ? (
            <DropzoneComponent
              onDrop={onDrop}
              allowedMimeTypes={allowedMimeTypes}
              content={DropzoneContent}
              useDropzone={useDropzone}
              hasImage={hasImage}
              compact={compact}
            />
          ) : (
            <FileTable>
              {fileHeader}
              <FileRowComponent isErrorRow={!fieldState.error} compact={true}>
                <Info>
                  <SuccessOrErrorIcon success={!fieldState.error} />
                  <Filename
                    filename={currentValue.originalFilename}
                    errorMessage={fieldState.error?.message}
                  />
                </Info>
                <Remove onClick={() => resetField(fieldName)} />
              </FileRowComponent>
            </FileTable>
          );
        }}
      />
    </>
  );
};
