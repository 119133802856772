import React, { ReactNode } from "react";
import Icon, { ICON_COLORS, Icons } from "../Common/Icons";
import {
  IconWrapper,
  Row,
  Subtitle,
  TextContainer,
  Title,
} from "./__styles__/FileDisplay";

export default ({
  fileIcon,
  titleText,
  children,
  expandable = false,
  expanded = false,
  onClick,
}: {
  fileIcon?: Icon;
  titleText: String;
  children?: ReactNode;
  expandable?: boolean;
  expanded?: boolean;
  onClick?: () => void;
}) => {
  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const accessibleKeysToTriggerClick = ["Enter", " "];

    if (accessibleKeysToTriggerClick.includes(event.key) && onClick) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <Row>
      {fileIcon && <Icon icon={fileIcon} />}
      <TextContainer
        onClick={onClick}
        onKeyDown={onKeyDown}
        expandable={expandable}
        noPadding={!fileIcon}
      >
        <Title>
          {titleText}
          {expandable && (
            <IconWrapper expanded={expanded} tabIndex={0}>
              <Icon icon={Icons.CARET_DOWN} color={ICON_COLORS.DARK_GREY} />
            </IconWrapper>
          )}
        </Title>
        {children && <Subtitle>{children}</Subtitle>}
      </TextContainer>
    </Row>
  );
};
