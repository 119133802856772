import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  Controller,
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { formatCollection } from "common/utils/strings";
import { styled } from "../../stitches.config";
import { Button } from "../Common/Button";
import { IconNames } from "../Common/Icons/LucideIcons";
import {
  dropdownCheckboxItemStyles,
  dropdownContentStyles,
  dropdownSeparatorStyles,
} from "./__styles__/TagSelect";
import Wrapper, { WrapperProps } from "./Wrapper";

export interface Option<T> {
  value: T;
  label: string;
}

export type TagSelectProps<T, IsMulti extends boolean> = {
  name: string;
  options: Array<Option<T>>;
  value: IsMulti extends true ? T[] : Maybe<T>;
  onChange: (value: IsMulti extends true ? T[] : Maybe<T>) => void;
  disabled?: boolean;
  leftIconName?: IconNames;
  isMulti: IsMulti;
};

/**
 * A checkbox menu that appears in a popover triggered by a button styled as a tag, with optional icon
 * - Provides a "select all" checkbox option at the top when isMulti=true
 * - Displays a list of checkboxes for each option
 * - Displays the selected options as a comma separated list on the trigger button
 */
const TagSelect = <T, IsMulti extends boolean>({
  name,
  options,
  value,
  onChange,
  disabled,
  leftIconName,
  isMulti,
}: TagSelectProps<T, IsMulti>) => {
  const optionValuesSet = new Set(options.map(option => option.value));

  const selectedValuesSet: Set<T> = isMulti
    ? new Set(value as T[])
    : new Set(value ? [value as T] : []);

  const triggerLabel =
    selectedValuesSet.size > 0
      ? formatCollection(
          options
            .filter(option => selectedValuesSet.has(option.value))
            .map(option => option.label)
        )
      : "Select...";

  const allAreSelected =
    optionValuesSet.size === selectedValuesSet.size &&
    Array.from(optionValuesSet).every(value => selectedValuesSet.has(value));

  const handleSelectAllCheckedChange = (checked: boolean) => {
    if (checked && isMulti) {
      (onChange as (value: T[]) => void)(Array.from(optionValuesSet));
    }
  };

  const handleOptionCheckedChange = (value: T, checked: boolean) => {
    const updatedValues = new Set(selectedValuesSet);
    if (checked) {
      if (!isMulti) {
        updatedValues.clear();
      }
      updatedValues.add(value);
    } else {
      updatedValues.delete(value);
    }
    if (isMulti) {
      (onChange as (value: T[]) => void)(Array.from(updatedValues));
    } else {
      const val = updatedValues.size > 0 ? Array.from(updatedValues)[0] : null;
      (onChange as (value: Maybe<T>) => void)(val as T);
    }
  };

  const handleSelectCheckbox = (event: Event) => {
    // prevent dropdown menu from closing when selecting that item when isMulti is true
    // see https://www.radix-ui.com/primitives/docs/components/dropdown-menu#checkboxitem
    if (isMulti) {
      event.preventDefault();
    }
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger disabled={disabled} asChild>
        <Button
          id={name}
          styleVariant="buttonTag"
          size="small"
          leftIconName={leftIconName}
        >
          {triggerLabel}
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownContent side="bottom" align="start">
          {isMulti && (
            <>
              <DropdownCheckboxItem
                checked={allAreSelected}
                onCheckedChange={handleSelectAllCheckedChange}
                onSelect={handleSelectCheckbox}
                id={`${name}_select-all`}
              >
                <input
                  type="checkbox"
                  checked={allAreSelected}
                  disabled={allAreSelected}
                  readOnly
                />
                Select All
              </DropdownCheckboxItem>
              <DropdownSeparator />
            </>
          )}
          {options.map(option => (
            <DropdownCheckboxItem
              checked={selectedValuesSet.has(option.value)}
              onCheckedChange={checked => {
                handleOptionCheckedChange(option.value, checked);
              }}
              key={`${option.value}`}
              id={`${name}__${option.value}`}
              onSelect={handleSelectCheckbox}
            >
              <input
                type="checkbox"
                checked={selectedValuesSet.has(option.value)}
                readOnly
              />
              {option.label}
            </DropdownCheckboxItem>
          ))}
        </DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default TagSelect;

// radix library requires the styled components to be declared in the same file
export const DropdownContent = styled(
  DropdownMenu.Content,
  dropdownContentStyles
);

export const DropdownCheckboxItem = styled(
  DropdownMenu.CheckboxItem,
  dropdownCheckboxItemStyles
);

export const DropdownSeparator = styled(
  DropdownMenu.Separator,
  dropdownSeparatorStyles
);

export type ReactHookFormTagSelectProps<
  T,
  TOutput,
  IsMulti extends boolean,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> &
  Omit<TagSelectProps<T, IsMulti>, "value" | "onChange"> &
  Pick<WrapperProps, "label" | "required" | "error"> & {
    transform?: {
      output: (value: IsMulti extends true ? T[] : Maybe<T>) => Maybe<TOutput>;
      input: (value: Maybe<TOutput>) => IsMulti extends true ? T[] : Maybe<T>;
    };
    onChange?: (value: Maybe<TOutput>) => void;
  };

export function ReactHookFormTagSelect<
  T,
  TOutput extends FieldPathValue<TFieldValues, TName>,
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  IsMulti extends boolean = false
>({
  control,
  name,
  rules,
  label,
  required,
  error,
  transform,
  onChange,
  ...reactTagSelectProps
}: ReactHookFormTagSelectProps<T, TOutput, IsMulti, TFieldValues, TName>) {
  return (
    <Wrapper label={label} required={required} name={name} error={error}>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => {
          return (
            <TagSelect<T, IsMulti>
              name={name}
              onChange={(value: IsMulti extends true ? T[] : Maybe<T>) => {
                const outputValue = transform?.output
                  ? transform.output(value)
                  : (value as Maybe<TOutput>);
                field.onChange(outputValue);
                onChange?.(outputValue);
              }}
              value={
                transform?.input ? transform.input(field.value) : field.value
              }
              {...reactTagSelectProps}
            />
          );
        }}
      />
    </Wrapper>
  );
}
